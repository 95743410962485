import { Action, createSlice, createSelector, ThunkAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "@store/store";
import config from "@/config";
import { OfficerState } from "@apptypes/complaints/officers-state";
import { Officer } from "@apptypes/person/person";
import { UUID } from "crypto";
import { PersonComplaintXref } from "@apptypes/complaints/person-complaint-xref";
import COMPLAINT_TYPES from "@apptypes/app/complaint-types";
import {
  updateWildlifeComplaintByRow,
  updateAllegationComplaintByRow,
  updateGeneralComplaintByRow,
  getComplaintById,
  selectComplaint,
} from "./complaints";
import { generateApiParameters, get, patch, post } from "@common/api";
import { from } from "linq-to-typescript";
import { NewPersonComplaintXref } from "@apptypes/api-params/new-person-complaint-xref";
import Option from "@apptypes/app/option";
import { toggleNotification } from "./app";
import { WildlifeComplaint as WildlifeComplaintDto } from "@apptypes/app/complaints/wildlife-complaint";
import { AllegationComplaint as AllegationComplaintDto } from "@apptypes/app/complaints/allegation-complaint";
import { OfficerDto } from "@apptypes/app/people/officer";
import { GeneralIncidentComplaint as GeneralIncidentComplaintDto } from "@apptypes/app/complaints/general-complaint";
import Roles from "@apptypes/app/roles";

const initialState: OfficerState = {
  officers: [],
};

export const officerSlice = createSlice({
  name: "officers",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setOfficers: (state, action) => {
      const {
        payload: { officers },
      } = action;
      return { ...state, officers };
    },
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {},
});

// export the actions/reducers
export const { setOfficers } = officerSlice.actions;

// Get list of the officers and update store
export const getOfficers =
  (zone?: string): AppThunk =>
  async (dispatch) => {
    try {
      const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/officer/`);
      const response = await get<Array<Officer>>(dispatch, parameters);

      if (response && from(response).any()) {
        dispatch(
          setOfficers({
            officers: response,
          }),
        );
      }
    } catch (error) {
      //-- handle errors
    }
  };

// Assigns the current user to an office
export const assignCurrentUserToComplaint =
  (userId: string, userGuid: UUID, complaint_identifier: string, complaint_type: string): AppThunk =>
  async (dispatch) => {
    try {
      let officerParams = generateApiParameters(`${config.API_BASE_URL}/v1/officer/find-by-auth-user-guid/${userGuid}`);
      let officerResponse = await get<Officer>(dispatch, officerParams);

      if (officerResponse.auth_user_guid === undefined) {
        officerParams = generateApiParameters(`${config.API_BASE_URL}/v1/officer/find-by-userid/${userId}`);

        let officerByUserIdResponse = await get<Officer>(dispatch, officerParams);
        const officerGuid = officerByUserIdResponse.officer_guid;

        officerParams = generateApiParameters(`${config.API_BASE_URL}/v1/officer/${officerGuid}`, {
          auth_user_guid: userGuid,
        });

        await patch<Officer>(dispatch, officerParams);
      }

      dispatch(
        updateComplaintAssignee(
          userId,
          complaint_identifier,
          complaint_type,
          officerResponse.person_guid.person_guid as UUID,
        ),
      );

      const parameters = generateApiParameters(
        `${config.API_BASE_URL}/v1/complaint/by-complaint-identifier/${complaint_type}/${complaint_identifier}`,
      );
      const response = await get<WildlifeComplaintDto | AllegationComplaintDto | GeneralIncidentComplaintDto>(
        dispatch,
        parameters,
      );

      if (complaint_type === COMPLAINT_TYPES.HWCR) {
        dispatch(updateWildlifeComplaintByRow(response as WildlifeComplaintDto));
      } else if (COMPLAINT_TYPES.GIR === complaint_type) {
        dispatch(updateGeneralComplaintByRow(response as GeneralIncidentComplaintDto));
      } else {
        dispatch(updateAllegationComplaintByRow(response as AllegationComplaintDto));
      }
    } catch (error) {
      //-- handle error
    }
  };

// creates a new cross reference for a person and office.  Assigns a person to an office.
export const updateComplaintAssignee =
  (currentUser: string, complaint_identifier: string, complaint_type: string, person_guid?: UUID): AppThunk =>
  async (dispatch) => {
    try {
      // add new person complaint record
      const payload = {
        active_ind: true,
        person_guid: {
          person_guid: person_guid,
        },
        complaint_identifier: complaint_identifier,
        person_complaint_xref_code: "ASSIGNEE",
        create_user_id: currentUser,
      } as NewPersonComplaintXref;

      // assign a complaint to a person
      let personComplaintXrefGuidParams = generateApiParameters(
        `${config.API_BASE_URL}/v1/person-complaint-xref/${complaint_identifier}`,
        payload,
      );
      await post<Array<PersonComplaintXref>>(dispatch, personComplaintXrefGuidParams);

      const parameters = generateApiParameters(
        `${config.API_BASE_URL}/v1/complaint/by-complaint-identifier/${complaint_type}/${complaint_identifier}`,
      );
      const response = await get<WildlifeComplaintDto | AllegationComplaintDto | GeneralIncidentComplaintDto>(
        dispatch,
        parameters,
      );

      // refresh complaints.  Note we should just update the changed record instead of the entire list of complaints
      if (COMPLAINT_TYPES.HWCR === complaint_type) {
        dispatch(updateWildlifeComplaintByRow(response as WildlifeComplaintDto));
      } else if (COMPLAINT_TYPES.GIR === complaint_type) {
        dispatch(updateGeneralComplaintByRow(response as GeneralIncidentComplaintDto));
      } else {
        dispatch(updateAllegationComplaintByRow(response as AllegationComplaintDto));
      }

      await dispatch(getComplaintById(complaint_identifier, complaint_type));
    } catch (error) {
      console.log(error);
    }
  };

//-- assign a user to a complaint and return the result of the assignment
export const assignComplaintToOfficer =
  (id: string, personId: string): ThunkAction<Promise<string | undefined>, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const {
      app: {
        profile: { idir_username: currentUser },
      },
    } = getState();

    // assign a complaint to a person
    let payload = generateApiParameters(`${config.API_BASE_URL}/v1/person-complaint-xref/${id}`, {
      active_ind: true,
      person_guid: {
        person_guid: personId,
      },
      complaint_identifier: id,
      person_complaint_xref_code: "ASSIGNEE",
      create_user_id: currentUser,
    });

    const result = await post<Array<PersonComplaintXref>>(dispatch, payload);

    if (result) {
      return "success";
    } else {
      return "error";
    }
  };

//-- selectors

export const selectOfficers = (state: RootState): Officer[] | null => {
  const { officers: officerRoot } = state;
  const { officers } = officerRoot;

  return officers;
};

export const searchOfficers =
  (input: string, agency: string) =>
  (state: RootState): Array<Officer> => {
    const {
      officers: { officers: items },
    } = state;
    let results: Array<Officer> = [];
    const searchInput = input.toLowerCase();
    const role = mapAgencyToRole(agency);

    //-- look for any officers that match firstname, lastname, or office
    if (input.length >= 2) {
      results = items.filter((officer) => {
        const {
          person_guid: { first_name: firstName, last_name: lastName },
          office_guid,
          user_roles,
        } = officer;

        // Safely handle office_guid and cos_geo_org_unit
        const fromAdminOffice = office_guid?.cos_geo_org_unit?.administrative_office_ind ?? undefined; // Will be undefined if cos_geo_org_unit is null or undefined

        const nameMatch =
          firstName.toLocaleLowerCase().includes(searchInput) || lastName.toLocaleLowerCase().includes(searchInput);
        const roleMatch = user_roles.includes(role) && !user_roles.includes(Roles.READ_ONLY);

        if (agency === "COS") {
          return !fromAdminOffice && nameMatch && roleMatch;
        } else if (agency === "EPO") {
          return roleMatch && nameMatch;
        } else {
          return false;
        }
      });
    }

    return results;
  };

export const selectOfficersDropdown =
  (includeAdminOffice: boolean) =>
  (state: RootState): Array<Option> => {
    const { officers: officerRoot } = state;
    const { officers } = officerRoot;

    const results = officers
      ?.filter((officer) => {
        const { office_guid } = officer;

        // Safely handle office_guid and cos_geo_org_unit
        const fromAdminOffice = office_guid?.cos_geo_org_unit?.administrative_office_ind ?? undefined; // Will be undefined if cos_geo_org_unit is null or undefined

        return includeAdminOffice ? true : !fromAdminOffice;
      })
      .map((item) => {
        const {
          person_guid: { person_guid: id, first_name, last_name },
        } = item;
        return { value: id, label: `${last_name}, ${first_name}` };
      });

    return results;
  };

// find officers that have an office in the given zone
export const selectOfficersByZone =
  (zone?: string) =>
  (state: RootState): Officer[] | null => {
    const { officers: officerRoot } = state;
    const { officers } = officerRoot;

    if (zone) {
      return officers.filter((officer) => {
        // check for nulls
        const zoneCode = officer?.office_guid?.cos_geo_org_unit?.zone_code ?? null;
        return zone === zoneCode;
      });
    }

    return [];
  };

// find officers that have an office in the given agency
const mapAgencyToRole = (agency: string): string => {
  let role: string = "";
  if (agency === "COS") {
    role = "COS Officer";
  } else if (agency === "EPO") {
    role = "CEEB";
  }
  return role;
};

const filterOfficerByAgency = (agency: string, officers: Officer[]) => {
  const role = mapAgencyToRole(agency);
  const result = officers.filter((officer) => {
    const { office_guid, user_roles } = officer;

    // Safely handle office_guid and cos_geo_org_unit
    const fromAdminOffice = office_guid?.cos_geo_org_unit?.administrative_office_ind ?? undefined; // Will be undefined if cos_geo_org_unit is null or undefined

    const roleMatch = user_roles.includes(role) && !user_roles.includes(Roles.READ_ONLY);
    const agencyCode = officer?.office_guid?.agency_code?.agency_code ?? null;

    if (agency === "COS") {
      return agency === agencyCode && !fromAdminOffice && roleMatch;
    } else if (agency === "EPO") {
      let result = roleMatch;
      return result;
    } else {
      return false;
    }
  });
  return result;
};

export const selectOfficersByAgency =
  (agency: string) =>
  (state: RootState): Officer[] | null => {
    const { officers: officerRoot } = state;
    const { officers } = officerRoot;
    const result = filterOfficerByAgency(agency, officers);
    return result;
  };

export const selectOfficerListByAgency = createSelector(
  [selectOfficers, selectComplaint],
  (officers, complaint): Array<Option> => {
    if (complaint?.ownedBy) {
      const officerList = filterOfficerByAgency(complaint.ownedBy, officers || []);
      const officerDropdown = officerList.map((officer: Officer) => ({
        value: officer.auth_user_guid,
        label: `${officer.person_guid.last_name}, ${officer.person_guid.first_name}`,
      }));
      return officerDropdown;
    }
    return [];
  },
);

export const selectOfficersByAgencyDropdownUsingPersonGuid =
  (agency: string) =>
  (state: RootState): Array<Option> => {
    const { officers: officerRoot } = state;
    const { officers } = officerRoot;
    const officerList = filterOfficerByAgency(agency, officers);
    const officerDropdown = officerList.map((officer: Officer) => ({
      value: officer.person_guid.person_guid,
      label: `${officer.person_guid.last_name}, ${officer.person_guid.first_name}`,
    }));

    return officerDropdown;
  };

export const selectOfficersByReportedBy =
  (reportedBy: string) =>
  (state: RootState): Officer[] | null => {
    const { officers: officerRoot } = state;
    const { officers } = officerRoot;

    return officers.filter((officer) => {
      // check for nulls
      const reportedByCode = officer?.office_guid?.reported_by_code?.reported_by_code ?? null;
      return reportedBy === reportedByCode;
    });
  };

export const selectOfficersByZoneAgencyAndRole =
  (agency: string, zone?: string) =>
  (state: RootState): Officer[] | null => {
    const { officers: officerRoot } = state;
    const { officers } = officerRoot;

    const role = mapAgencyToRole(agency);
    let result: boolean = false;

    if (agency === "COS") {
      if (zone) {
        return officers.filter((officer) => {
          const zoneCode = officer?.office_guid?.cos_geo_org_unit?.zone_code ?? null;
          const agencyCode = officer?.office_guid?.agency_code?.agency_code ?? null;
          const fromAdminOffice = officer?.office_guid?.cos_geo_org_unit?.administrative_office_ind;
          const zoneAgencyMatch = zone === zoneCode && (agency === agencyCode || !agency);
          const roleMatch = officer?.user_roles.includes(role) && !officer?.user_roles.includes(Roles.READ_ONLY);
          result = !fromAdminOffice && zoneAgencyMatch && roleMatch;
          return result;
        });
      }
    } else if (agency === "EPO") {
      return officers.filter((officer) => {
        result = officer?.user_roles.includes(role) && !officer?.user_roles.includes(Roles.READ_ONLY);
        return result;
      });
    }
    return [];
  };

export const assignOfficerToOffice =
  (personId: string, officeId: string): AppThunk =>
  async (dispatch, getState) => {
    const {
      officers: { officers },
    } = getState();

    try {
      const selectedOfficer = officers.find((item) => {
        const { person_guid: person } = item;
        const { person_guid: _personId } = person;

        return personId === _personId;
      });

      const { office_guid: office } = selectedOfficer || {};
      const updatedOffice = { ...office, office_guid: officeId };

      const update = { ...selectedOfficer, office_guid: updatedOffice };

      const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/officer/${selectedOfficer?.officer_guid}`, {
        ...update,
      });

      const response = await patch<Array<Officer>>(dispatch, parameters);

      if (response && from(response).any()) {
        dispatch(toggleNotification("success", "officer assigned"));
      }
    } catch (error) {
      //-- handle errors
      dispatch(toggleNotification("error", "unable to assign officer to office"));
    }
  };

export const selectOfficerByIdir =
  (idir: string) =>
  (state: RootState): Officer | null => {
    const {
      officers: { officers: data },
    } = state;
    const selected = data.find(({ user_id }) => user_id === idir);

    if (selected?.person_guid) {
      return selected;
    }

    return null;
  };

export const selectOfficerByAuthUserGuid =
  (userGuid: string) =>
  (state: RootState): Officer | null => {
    const {
      officers: { officers: data },
    } = state;
    const selected = data.find(({ auth_user_guid }) => auth_user_guid === userGuid);

    if (selected?.auth_user_guid) {
      return selected;
    }

    return null;
  };

export const selectCurrentOfficer =
  () =>
  (state: RootState): OfficerDto | null => {
    const {
      app: {
        profile: { idir_username: idir },
      },
      officers: { officers: data },
    } = state;
    const selected = data.find(({ user_id }) => user_id === idir);

    if (selected?.person_guid) {
      const { person_guid: person, office_guid: office, officer_guid, user_id, auth_user_guid } = selected;
      const { person_guid, first_name: firstName, last_name: lastName } = person;

      const officerId = officer_guid as UUID;
      const personId = person_guid as UUID;

      const officerData = {
        id: officerId,
        userId: user_id,
        authorizedUserId: auth_user_guid,
        person: { id: personId, firstName, lastName },
      };

      // Add office details if officer has an office
      if (office) {
        const { office_guid, cos_geo_org_unit: location } = office;
        const officeId = office_guid as UUID;
        return {
          ...officerData,
          office: { ...location, id: officeId },
        };
      }

      return officerData;
    }

    return null;
  };

export default officerSlice.reducer;
