export const FEATURE_TYPES = {
  EXPERIMENTAL_FEATURE: "EXPERMFTRS",
  GIR_COMPLAINT: "GIRCMPLNTS",
  ACTION_TAKEN: "ACTONSTKEN",
  ACTION_TAKEN_FILTER: "ACT_FLTR",
  COMMUNITY_FILTER: "COM_FLTR",
  DATE_LOGGED_FILTER: "D_L_FLTR",
  GIR_FILTER: "GIR_FLTR",
  METHOD_FILTER: "METH_FLTR",
  NATURE_FILTER: "NAT_FLTR",
  OFFICER_ASSIGNED_FILTER: "OFF_FLTR",
  REGION_FILTER: "REG_FLTR",
  SPECIES_FILTER: "SPEC_FLTR",
  STATUS_FILTER: "STAT_FLTR",
  VIOLATION_TYPE_FILTER: "VIOL_FLTR",
  ZONE_FILTER: "ZONE_FLTR",
  PRIV_REQ: "PRIV_REQ",
  ENABLE_OFFICE: "ENBL_OFF",
  EXTERNAL_FILE_REFERENCE: "EXTRNALREF",
  OUTCOME_ANIMAL_FILTER: "OUT_A_FLTR",
};
