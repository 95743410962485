import { Office } from "@apptypes/office/office";
import { OfficeState } from "@apptypes/office/offices-in-zone-state";
import { AppThunk, RootState } from "@store/store";
import { createSlice } from "@reduxjs/toolkit";
import config from "@/config";
import { generateApiParameters, get } from "@common/api";
import { from } from "linq-to-typescript";
import { OfficeAssignment } from "@apptypes/app/office/office-assignment";
import { DropdownOption } from "@apptypes/app/drop-down-option";

const initialState: OfficeState = {
  officesInZone: [],
  officeAssignments: [],
};

export const officeSlice = createSlice({
  name: "offices",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setOfficesInZone: (state, action) => {
      const { payload } = action;
      const officesInZone: Office[] = payload.officesInZone;
      return { ...state, officesInZone };
    },
    setOfficeAssignments: (state, action) => {
      const {
        payload: { offices },
      } = action;

      return { ...state, officeAssignments: offices };
    },
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {},
});

// export the actions/reducers
export const { setOfficesInZone, setOfficeAssignments } = officeSlice.actions;

// Given a zone, returns a list of persons in that zone.
export const getOfficesInZone =
  (zone?: string): AppThunk =>
  async (dispatch) => {
    const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/office/by-zone/${zone}`);
    const response = await get<Array<Office>>(dispatch, parameters);

    if (response && from(response).any()) {
      dispatch(
        setOfficesInZone({
          officesInZone: response,
        }),
      );
    }
  };

export const fetchOfficeAssignments = (): AppThunk => async (dispatch) => {
  let parameters = generateApiParameters(`${config.API_BASE_URL}/v1/office/offices-by-agency`);

  let response = await get<Array<OfficeAssignment>>(dispatch, parameters);

  if (response && from(response).any()) {
    const payload = {
      offices: response,
    };
    dispatch(setOfficeAssignments(payload));
  }
};

export const selectOfficesForAssignmentDropdown = (state: RootState): Array<DropdownOption> => {
  const {
    offices: { officeAssignments },
  } = state;

  const data = officeAssignments?.map((item) => {
    const { id, name, agency } = item;
    const record: DropdownOption = {
      label: `${agency} - ${name}`,
      value: id,
    };

    return record;
  });
  return data;
};

export const selectOffices = (state: RootState): Array<OfficeAssignment> => {
  const {
    offices: { officeAssignments },
  } = state;

  return officeAssignments;
};

export default officeSlice.reducer;
